/**
 * @generated SignedSource<<0f8dae1172251c99cec449062e0f0a81>>
 * @relayHash ca502405166110fab14d9c351fe3f6c8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e277e4ddd2d16f6811b965abfa89ee6c4bc3c061c39fa920c3929718028291a9

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_jiraCalendarQuery } from './ui_jiraCalendarQuery.graphql';

const node: PreloadableConcreteRequest<ui_jiraCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e277e4ddd2d16f6811b965abfa89ee6c4bc3c061c39fa920c3929718028291a9",
    "metadata": {},
    "name": "ui_jiraCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
