import React, { type ComponentType } from 'react';
import { GADGET_DEFAULT_HEIGHT } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { ReactGadgetProps } from '@atlassian/jira-react-gadgets-common/src/types.tsx';
import { FilterResultsIssueTableSkeleton } from '@atlassian/jira-skeletons/src/ui/dashboard/components/filter-results-issue-table.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { IssueTableGadgetData } from '../../types';

const LazyIssueTableGadgetView = lazyForPaint<
	ComponentType<ReactGadgetProps<IssueTableGadgetData>>
>(() => import(/* webpackChunkName: "async-react-filter-results-gadget-view" */ './main'));

export const IssueTableGadgetView = ({
	customSkeletonHeightInPx,
	...props
}: ReactGadgetProps<IssueTableGadgetData>) => (
	<Placeholder
		name="filter-results-gadget-view"
		fallback={
			<FilterResultsIssueTableSkeleton
				height={`${customSkeletonHeightInPx ?? GADGET_DEFAULT_HEIGHT}px`}
			/>
		}
	>
		<LazyIssueTableGadgetView customSkeletonHeightInPx={customSkeletonHeightInPx} {...props} />
	</Placeholder>
);

export const { preload } = LazyIssueTableGadgetView;
