/**
 * @generated SignedSource<<b9ab68691276dfb049d592779c70bacb>>
 * @relayHash 45acd10b6ad33e22a13040d5c4fbed9c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 82bac835e30354bbb78d1e441b23588d10ecb22afba1a3dd0aeb233947ff4165

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type JiraContainerNavigationQueryInput = {
  projectKeyQuery?: JiraContainerNavigationByProjectKeyQueryInput | null | undefined;
  scopeId?: string | null | undefined;
};
export type JiraContainerNavigationByProjectKeyQueryInput = {
  cloudId: string;
  projectKey: string;
};
export type uiHorizontalSoftwareProjectNavQuery$variables = {
  boardId: string;
  cloudId: string;
  containerNavigationInput: JiraContainerNavigationQueryInput;
  first: number;
  hasBoardId: boolean;
  isTmpRoute: boolean;
  projectAri: string;
  projectKey: string;
  useProjectAri: boolean;
  useProjectKey: boolean;
};
export type uiHorizontalSoftwareProjectNavQuery$data = {
  readonly jira: {
    readonly containerNavigation: {
      readonly canEdit?: boolean | null | undefined;
      readonly navigationItems?: {
        readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitTabList_TabList_tabsFragment">;
      } | null | undefined;
      readonly scopeId?: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitAddTab_AddTab" | "ui_navigationKitTabList_TabList_containerFragment">;
    };
    readonly projectDataById?: {
      readonly __id: string;
      readonly avatar: {
        readonly large: string | null | undefined;
      } | null | undefined;
      readonly canEditProjectConfig: {
        readonly canPerform: boolean;
      } | null | undefined;
      readonly canViewProjectConfig: {
        readonly canPerform: boolean;
      } | null | undefined;
      readonly key: string;
      readonly name: string;
      readonly projectId: string | null | undefined;
      readonly projectStyle: JiraProjectStyle | null | undefined;
      readonly projectType: JiraProjectType | null | undefined;
      readonly softwareBoardCount: AGG$Long | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"projectHeader_horizontalNavHeader" | "ui_navigationKitTabList_TabList_projectFragment">;
    };
    readonly projectDataByKey?: {
      readonly __id: string;
      readonly avatar: {
        readonly large: string | null | undefined;
      } | null | undefined;
      readonly canEditProjectConfig: {
        readonly canPerform: boolean;
      } | null | undefined;
      readonly canViewProjectConfig: {
        readonly canPerform: boolean;
      } | null | undefined;
      readonly key: string;
      readonly name: string;
      readonly projectId: string | null | undefined;
      readonly projectStyle: JiraProjectStyle | null | undefined;
      readonly projectType: JiraProjectType | null | undefined;
      readonly softwareBoardCount: AGG$Long | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"projectHeader_horizontalNavHeader" | "ui_navigationKitTabList_TabList_projectFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"boardHeader_horizontalNavHeader">;
  };
};
export type uiHorizontalSoftwareProjectNavQuery = {
  response: uiHorizontalSoftwareProjectNavQuery$data;
  variables: uiHorizontalSoftwareProjectNavQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "containerNavigationInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasBoardId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTmpRoute"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectAri"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useProjectAri"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useProjectKey"
},
v10 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "containerNavigationInput"
  }
],
v11 = {
  "kind": "ScalarField",
  "name": "canEdit"
},
v12 = {
  "kind": "ScalarField",
  "name": "scopeId"
},
v13 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v14 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v15 = [
  (v14/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v16 = {
  "kind": "ScalarField",
  "name": "name"
},
v17 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v18 = {
  "kind": "ScalarField",
  "name": "projectType"
},
v19 = {
  "kind": "ScalarField",
  "name": "projectStyle"
},
v20 = {
  "kind": "ScalarField",
  "name": "key"
},
v21 = {
  "kind": "ScalarField",
  "name": "softwareBoardCount"
},
v22 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v23 = {
  "alias": "canEditProjectConfig",
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "EDIT_PROJECT_CONFIG"
    }
  ],
  "concreteType": "JiraProjectAction",
  "kind": "LinkedField",
  "name": "action",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
},
v24 = {
  "alias": "canViewProjectConfig",
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "VIEW_PROJECT_CONFIG"
    }
  ],
  "concreteType": "JiraProjectAction",
  "kind": "LinkedField",
  "name": "action",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
},
v25 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "large"
    }
  ]
},
v26 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v27 = [
  {
    "kind": "FragmentSpread",
    "name": "projectHeader_horizontalNavHeader"
  },
  {
    "kind": "FragmentSpread",
    "name": "ui_navigationKitTabList_TabList_projectFragment"
  },
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/),
  (v21/*: any*/),
  (v23/*: any*/),
  (v24/*: any*/),
  (v25/*: any*/),
  (v26/*: any*/)
],
v28 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v29 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v30 = {
  "kind": "ScalarField",
  "name": "id"
},
v31 = {
  "kind": "ScalarField",
  "name": "label"
},
v32 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v33 = {
  "kind": "ScalarField",
  "name": "url"
},
v34 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v35 = {
  "kind": "InlineFragment",
  "selections": [
    (v30/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v36 = {
  "kind": "ScalarField",
  "name": "boardId"
},
v37 = {
  "kind": "ScalarField",
  "name": "boardType"
},
v38 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v39 = [
  (v29/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v36/*: any*/),
      (v16/*: any*/),
      (v11/*: any*/),
      (v37/*: any*/),
      {
        "concreteType": "JiraFavouriteValue",
        "kind": "LinkedField",
        "name": "favouriteValue",
        "plural": false,
        "selections": [
          (v38/*: any*/),
          (v30/*: any*/)
        ]
      },
      (v26/*: any*/)
    ],
    "type": "JiraBoard"
  },
  (v35/*: any*/)
],
v40 = [
  {
    "kind": "LinkedField",
    "name": "mostRecentlyViewedBoard",
    "plural": false,
    "selections": (v39/*: any*/)
  },
  (v30/*: any*/)
],
v41 = [
  (v16/*: any*/),
  (v17/*: any*/),
  (v19/*: any*/),
  (v25/*: any*/),
  (v23/*: any*/),
  (v30/*: any*/),
  {
    "alias": "projectKey",
    "kind": "ScalarField",
    "name": "key"
  },
  (v38/*: any*/),
  (v18/*: any*/),
  {
    "kind": "ScalarField",
    "name": "webUrl"
  },
  {
    "kind": "ScalarField",
    "name": "status"
  },
  (v24/*: any*/),
  (v26/*: any*/),
  {
    "kind": "LinkedField",
    "name": "navigationMetadata",
    "plural": false,
    "selections": [
      (v29/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v36/*: any*/),
          {
            "kind": "ScalarField",
            "name": "isSimpleBoard"
          },
          (v30/*: any*/)
        ],
        "type": "JiraSoftwareProjectNavigationMetadata"
      }
    ]
  },
  {
    "kind": "LinkedField",
    "name": "mostRecentlyViewedBoard",
    "plural": false,
    "selections": [
      (v29/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v36/*: any*/),
          (v37/*: any*/)
        ],
        "type": "JiraBoard"
      },
      (v35/*: any*/)
    ]
  },
  (v20/*: any*/),
  (v21/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v10/*: any*/),
                "kind": "LinkedField",
                "name": "containerNavigation",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "ui_navigationKitTabList_TabList_containerFragment"
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "ui_navigationKitAddTab_AddTab"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "args": (v13/*: any*/),
                        "concreteType": "JiraNavigationItemConnection",
                        "kind": "LinkedField",
                        "name": "navigationItems",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "ui_navigationKitTabList_TabList_tabsFragment"
                          }
                        ]
                      }
                    ],
                    "type": "JiraContainerNavigation"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.containerNavigation"
            },
            {
              "condition": "isTmpRoute",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "boardId",
                      "variableName": "boardId"
                    },
                    (v14/*: any*/),
                    {
                      "kind": "Variable",
                      "name": "hasBoardId",
                      "variableName": "hasBoardId"
                    },
                    {
                      "kind": "Variable",
                      "name": "projectAri",
                      "variableName": "projectAri"
                    },
                    {
                      "kind": "Variable",
                      "name": "projectKey",
                      "variableName": "projectKey"
                    },
                    {
                      "kind": "Variable",
                      "name": "useProjectAri",
                      "variableName": "useProjectAri"
                    },
                    {
                      "kind": "Variable",
                      "name": "useProjectKey",
                      "variableName": "useProjectKey"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "boardHeader_horizontalNavHeader"
                }
              ]
            },
            {
              "condition": "useProjectKey",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": "projectDataByKey",
                    "args": (v15/*: any*/),
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "jiraProjectByKey",
                    "plural": false,
                    "selections": (v27/*: any*/)
                  },
                  "action": "THROW",
                  "path": "jira.projectDataByKey"
                }
              ]
            },
            {
              "condition": "useProjectKey",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "condition": "useProjectAri",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": "projectDataById",
                        "args": (v28/*: any*/),
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "jiraProject",
                        "plural": false,
                        "selections": (v27/*: any*/)
                      },
                      "action": "THROW",
                      "path": "jira.projectDataById"
                    }
                  ]
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v9/*: any*/),
      (v8/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v10/*: any*/),
            "kind": "LinkedField",
            "name": "containerNavigation",
            "plural": false,
            "selections": [
              (v29/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isJiraContainerNavigationResult"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v30/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "args": (v13/*: any*/),
                    "concreteType": "JiraNavigationItemConnection",
                    "kind": "LinkedField",
                    "name": "navigationItems",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraNavigationItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              (v30/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "isDefault"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "typeKey"
                              },
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isJiraNavigationItem"
                              },
                              (v31/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "canRemove"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "canSetAsDefault"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "appId"
                                  },
                                  {
                                    "concreteType": "JiraAppSection",
                                    "kind": "LinkedField",
                                    "name": "sections",
                                    "plural": true,
                                    "selections": [
                                      (v31/*: any*/),
                                      {
                                        "concreteType": "JiraAppNavigationItemNestedLink",
                                        "kind": "LinkedField",
                                        "name": "links",
                                        "plural": true,
                                        "selections": [
                                          (v31/*: any*/),
                                          (v32/*: any*/),
                                          (v33/*: any*/),
                                          (v34/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  (v33/*: any*/),
                                  (v32/*: any*/),
                                  (v34/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "appType"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "envLabel"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v26/*: any*/)
                                    ],
                                    "type": "JiraNavigationItem",
                                    "abstractKey": "__isJiraNavigationItem"
                                  }
                                ],
                                "type": "JiraAppNavigationItem"
                              },
                              (v26/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ],
                "type": "JiraContainerNavigation"
              },
              (v35/*: any*/)
            ]
          },
          {
            "condition": "isTmpRoute",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "condition": "hasBoardId",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "boardId"
                      }
                    ],
                    "kind": "LinkedField",
                    "name": "jiraBoard",
                    "plural": false,
                    "selections": (v39/*: any*/)
                  }
                ]
              },
              {
                "condition": "useProjectKey",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "condition": "hasBoardId",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "alias": "projectDataByKey",
                        "args": (v15/*: any*/),
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "jiraProjectByKey",
                        "plural": false,
                        "selections": (v40/*: any*/)
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "useProjectAri",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "condition": "hasBoardId",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "alias": "projectDataById",
                        "args": (v28/*: any*/),
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "jiraProject",
                        "plural": false,
                        "selections": (v40/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "condition": "useProjectKey",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "projectDataByKey",
                "args": (v15/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": (v41/*: any*/)
              }
            ]
          },
          {
            "condition": "useProjectKey",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "condition": "useProjectAri",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "projectDataById",
                    "args": (v28/*: any*/),
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "jiraProject",
                    "plural": false,
                    "selections": (v41/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "82bac835e30354bbb78d1e441b23588d10ecb22afba1a3dd0aeb233947ff4165",
    "metadata": {},
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "742b31215677c39447507dac79ff03b0";

export default node;
