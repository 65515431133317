import { arjCalendarEmbedRouteEntry } from './calendar';
import { arjIncrementEmbedRouteEntry } from './increment';
import { arjSummaryEmbedRouteEntry } from './summary';
import { arjTimelineEmbedRouteEntry } from './timeline';

export const arjEmbedRouteEntries = [
	arjCalendarEmbedRouteEntry,
	arjIncrementEmbedRouteEntry,
	arjSummaryEmbedRouteEntry,
	arjTimelineEmbedRouteEntry,
];
