/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, {
	type CSSProperties,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

import { cssMap, jsx } from '@compiled/react';
import { bind } from 'bind-event-listener';

import {
	OpenLayerObserver,
	useOpenLayerObserver,
} from '@atlaskit/layering/experimental/open-layer-observer';
import { fg } from '@atlaskit/platform-feature-flags';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import { InteractionSurface } from '../../../components/interaction-surface';
import { HomeActionsElement } from '../../../context/home-actions/home-actions-context';
import { useSkipLink } from '../../../context/skip-links/skip-links-context';
import {
	contentHeightWhenFixed,
	contentInsetBlockStart,
	localSlotLayers,
	sideNavVar,
	UNSAFE_sideNavLayoutVar,
} from '../constants';
import { DangerouslyHoistSlotSizes } from '../hoist-slot-sizes-context';
import { DangerouslyHoistCssVarToDocumentRoot } from '../hoist-utils';
import { PanelSplitterProvider } from '../panel-splitter/provider';
import type { ResizeBounds } from '../panel-splitter/types';
import { usePrefixedUID } from '../use-prefixed-id';

import { FlyoutLockCount } from './flyout-lock-context';
import { SideNavToggleButtonElement } from './toggle-button-context';
import { useSideNavVisibility } from './use-side-nav-visibility';
import {
	useSideNavVisibilityCallbacks,
	type VisibilityCallback,
} from './use-side-nav-visibility-callbacks';
import { useToggleSideNav } from './use-toggle-side-nav';

const panelSplitterResizingVar = '--n_snvRsz';

const widthResizeBounds: ResizeBounds = { min: '160px', max: '50vw' };

const sideNavFlyoutCloseDelayMs = 400;

type SideNavState =
	| 'idle'
	| 'hiddenMobileOnly'
	| 'hiddenDesktopOnly'
	| 'hiddenMobileAndDesktop'
	| 'flyout';

/**
 * Returns the current state of the side nav based on the provided props.
 * It has been ordered to ensure that the flyout state is least prioritised over the other toggled visibility states
 */
const getSideNavState = ({
	visibleOnDesktop,
	visibleOnMobile,
	isFlyoutVisible,
}: {
	visibleOnDesktop: boolean;
	visibleOnMobile: boolean;
	isFlyoutVisible: boolean;
}): SideNavState => {
	if (visibleOnDesktop && visibleOnMobile) {
		return 'idle';
	}

	if (!visibleOnMobile && visibleOnDesktop) {
		return 'hiddenMobileOnly';
	}

	if (!visibleOnDesktop && isFlyoutVisible) {
		return 'flyout';
	}

	if (visibleOnMobile && !visibleOnDesktop) {
		return 'hiddenDesktopOnly';
	}

	return 'hiddenMobileAndDesktop';
};

const styles = cssMap({
	root: {
		backgroundColor: token('elevation.surface.overlay'),
		boxShadow: token('elevation.shadow.overlay'),
		gridArea: 'main / aside / aside / aside',
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		// Since the side nav is always rendered ontop of other grid items across all viewports height is
		// always set.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		height: contentHeightWhenFixed,
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		insetBlockStart: contentInsetBlockStart,
		position: 'sticky',
		// For mobile viewports, the side nav will take up 90% of the screen width, up to a maximum of 320px (the default SideNav width)
		width: 'min(90%, 320px)',
		// On small viewports the side nav is displayed above other slots so we create a stacking context.
		// We keep the side nav with a stacking context always so it is rendered above main content.
		// This comes with a caveat that main is rendered underneath the side nav content so for any
		// menu dialogs rendered with "shouldRenderToParent" they could be cut off unintentionally.
		// Unfortunately this is the best of bad solutions.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		zIndex: localSlotLayers.sideNav,
		'@media (min-width: 48rem)': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
			width: `var(${panelSplitterResizingVar}, var(${sideNavVar}))`,
		},
		'@media (min-width: 64rem)': {
			backgroundColor: token('elevation.surface'),
			boxShadow: 'initial',
			gridArea: 'side-nav',
		},
	},
	flyout: {
		'@media (min-width: 64rem)': {
			backgroundColor: token('elevation.surface.overlay'),
			boxShadow: token('elevation.shadow.overlay'),
			gridArea: 'main',
		},
	},
	interactionSurface: {
		// Taking full space of container to capture hover interactions on the entire side nav
		width: '100%',
		height: '100%',
	},
	hiddenMobileAndDesktop: {
		display: 'none',
	},
	hiddenMobileOnly: {
		display: 'none',
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	hiddenDesktopOnly: {
		'@media (min-width: 64rem)': {
			display: 'none',
		},
	},
});

type SideNavProps = {
	children: React.ReactNode;
	/**
	 * Whether the side nav should be collapsed by default __on desktop screens__.
	 *
	 * It is always collapsed by default for mobile screens.
	 *
	 * __Note:__ If using this prop, ensure that it is also provided to the `SideNavToggleButton`.
	 * This is to ensure the state is in sync before post-SSR hydration.
	 */
	defaultCollapsed?: boolean;
	defaultWidth?: number;
	testId?: string;
	label?: string;
	/**
	 * Called when the side nav is expanded.
	 */
	onExpand?: VisibilityCallback;
	/**
	 * Called when the side nav is collapsed.
	 */
	onCollapse?: VisibilityCallback;
	id?: string;
};

/**
 * This component is used while rolling out the refactor to the side nav flyout lock.
 * It will be cleaned up once the rollout is complete.
 *
 * https://jplat.atlassian.net/browse/BLU-4636
 * https://switcheroo.atlassian.com/ui/gates/fa57329a-7759-4128-975c-7d6f5e7e4f60/key/platform_design_system_team_layering_observer
 */
function SideNavInternalLegacy({
	children,
	defaultCollapsed,
	defaultWidth = 320,
	testId,
	label = 'Side Navigation',
	onExpand,
	onCollapse,
	id,
}: SideNavProps) {
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	useSkipLink(CID, label);
	const { visibleOnDesktop, visibleOnMobile, setVisibleOnDesktop } = useSideNavVisibility({
		defaultCollapsed,
	});
	const [width, setWidth] = useState(defaultWidth);
	const clampedWidth = `clamp(${widthResizeBounds.min}, ${width}px, ${widthResizeBounds.max})`;
	const dangerouslyHoistSlotSizes = useContext(DangerouslyHoistSlotSizes);
	const navRef = useRef<HTMLDivElement | null>(null);
	const toggleButtonElement = useContext(SideNavToggleButtonElement);
	const homeActionsElement = useContext(HomeActionsElement);
	const devTimeOnlyAttributes: Record<string, string | boolean> = {};
	const [isFlyoutActive, setIsFlyoutActive] = useState(false);
	const flyoutLockCount = useContext(FlyoutLockCount);
	const isFlyoutLocked = flyoutLockCount > 0;

	// The flyout is open if:
	// a. The user has actively hovered over the flyout areas (side nav toggle button, side nav), or
	// b. The flyout is locked open by a flyout lock
	const isFlyoutVisible = isFlyoutActive || isFlyoutLocked;

	const sideNavState = getSideNavState({
		visibleOnDesktop,
		visibleOnMobile,
		isFlyoutVisible,
	});

	// A timeout reference is used to close the flyout after a delay when the user mouses out of the flyout area, and to allow
	// us to cancel the close if the user mouses back in.
	const closeFlyoutTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const cancelPendingFlyoutClose = useCallback(() => {
		if (closeFlyoutTimerRef.current) {
			clearTimeout(closeFlyoutTimerRef.current);
			closeFlyoutTimerRef.current = null;
		}
	}, []);

	const openFlyout = useCallback(() => {
		cancelPendingFlyoutClose();
		setIsFlyoutActive(true);
	}, [cancelPendingFlyoutClose]);

	// Starts a timer to close the flyout after a delay. It will only start a new timer if one isn't already pending.
	const tryStartCloseFlyoutTimer = useCallback(() => {
		if (closeFlyoutTimerRef.current) {
			// There is already a close timer pending, so we won't need to start another one.
			return;
		}

		closeFlyoutTimerRef.current = setTimeout(() => {
			closeFlyoutTimerRef.current = null;
			setIsFlyoutActive(false);
		}, sideNavFlyoutCloseDelayMs);
	}, []);

	const toggleVisibility = useToggleSideNav();

	// Sync the visibility in context with the local state value after SSR hydration, as it contains the default value from props
	useEffect(() => {
		setVisibleOnDesktop(visibleOnDesktop);
	}, [setVisibleOnDesktop, visibleOnDesktop]);

	useSideNavVisibilityCallbacks({
		onExpand,
		onCollapse,
		visibleOnDesktop,
		visibleOnMobile,
	});

	useEffect(() => {
		const mediaQueryList = window.matchMedia('(min-width: 64rem)');
		return bind(mediaQueryList, {
			type: 'change',
			listener() {
				if (mediaQueryList.matches) {
					// We're transitioning from tablet to desktop viewport size.
					// We forcibly show the side nav if it was shown on mobile.
					if (visibleOnMobile && !visibleOnDesktop) {
						toggleVisibility();
					}
				}
			},
		});
	}, [toggleVisibility, visibleOnDesktop, visibleOnMobile]);

	useEffect(() => {
		if (!toggleButtonElement) {
			return;
		}

		return bind(toggleButtonElement, {
			type: 'mouseenter',
			listener() {
				// Only flyout the side nav if the user hovered while the side nav was collapsed
				if (visibleOnDesktop) {
					return;
				}

				// Only flyout the side nav on desktop viewports
				const { matches } = window.matchMedia('(min-width: 64rem)');
				if (matches) {
					openFlyout();
				}
			},
		});
	}, [openFlyout, toggleButtonElement, visibleOnDesktop]);

	useEffect(() => {
		if (!toggleButtonElement) {
			return;
		}

		return bind(toggleButtonElement, {
			type: 'mouseleave',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (!isFlyoutVisible) {
					return;
				}

				tryStartCloseFlyoutTimer();
			},
		});
	}, [tryStartCloseFlyoutTimer, isFlyoutVisible, toggleButtonElement]);

	useEffect(() => {
		if (!navRef.current) {
			return;
		}

		return bind(navRef.current, {
			type: 'mouseenter',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (visibleOnDesktop || !isFlyoutVisible) {
					return;
				}

				// If the user mouses back over the side nav we want to cancel any pending flyout close timers
				cancelPendingFlyoutClose();

				// If the user mouses over the side nav while it was locked, we want to keep it open
				if (isFlyoutLocked) {
					const { matches } = window.matchMedia('(min-width: 64rem)');
					if (matches) {
						openFlyout();
					}
				}
			},
		});
	}, [cancelPendingFlyoutClose, isFlyoutLocked, isFlyoutVisible, openFlyout, visibleOnDesktop]);

	useEffect(() => {
		if (!navRef.current) {
			return;
		}

		return bind(navRef.current, {
			type: 'mouseleave',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (!isFlyoutVisible) {
					return;
				}

				tryStartCloseFlyoutTimer();
			},
		});
	}, [tryStartCloseFlyoutTimer, isFlyoutVisible]);

	useEffect(() => {
		if (!homeActionsElement || !toggleButtonElement) {
			return;
		}

		return bind(homeActionsElement, {
			type: 'mouseover',
			listener(event) {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (visibleOnDesktop || !isFlyoutVisible) {
					return;
				}

				if (event.target === homeActionsElement) {
					// The mouse is directly over the home actions container, so cancel any pending flyout closes.
					cancelPendingFlyoutClose();
					return;
				}

				if (event.target instanceof Element && toggleButtonElement.contains(event.target)) {
					// The mouse is over the toggle button or any of its children, so we don't want to close the flyout.
					// We also don't need to cancel any pending closes, as we have separate event listeners for the toggle button mouse events.
					return;
				}

				// The user has moused over a child element of the home actions container that isn't the toggle button, e.g. the app switcher or nav logo,
				// so we should close the flyout (with a delay).
				tryStartCloseFlyoutTimer();
			},
		});
	}, [
		cancelPendingFlyoutClose,
		tryStartCloseFlyoutTimer,
		homeActionsElement,
		isFlyoutVisible,
		toggleButtonElement,
		visibleOnDesktop,
	]);

	useEffect(() => {
		if (!homeActionsElement) {
			return;
		}

		return bind(homeActionsElement, {
			type: 'mouseleave',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (!isFlyoutVisible) {
					return;
				}

				// The mouse has left the home actions container, so we should close the flyout with a delay.
				tryStartCloseFlyoutTimer();
			},
		});
	}, [cancelPendingFlyoutClose, tryStartCloseFlyoutTimer, homeActionsElement, isFlyoutVisible]);

	useEffect(() => {
		// Clear flyout close timer if being unmounted
		return cancelPendingFlyoutClose;
	}, [cancelPendingFlyoutClose]);

	if (process.env.NODE_ENV !== 'production') {
		const visible: string[] = [];
		if (visibleOnMobile) {
			visible.push('small');
		}
		if (visibleOnDesktop) {
			visible.push('large');
		}
		if (isFlyoutVisible) {
			visible.push('flyout');
		}
		devTimeOnlyAttributes['data-visible'] = visible.length ? visible.join(',') : 'false';
	}

	return (
		<nav
			id={CID}
			{...devTimeOnlyAttributes}
			data-layout-slot
			aria-label={label}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={
				{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					[sideNavVar]: clampedWidth,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				} as CSSProperties
			}
			ref={navRef}
			css={[
				styles.root,
				sideNavState === 'hiddenMobileOnly' && styles.hiddenMobileOnly,
				sideNavState === 'hiddenDesktopOnly' && styles.hiddenDesktopOnly,
				sideNavState === 'hiddenMobileAndDesktop' && styles.hiddenMobileAndDesktop,
				sideNavState === 'flyout' && styles.flyout,
			]}
			data-testid={testId}
		>
			{dangerouslyHoistSlotSizes && (
				// ------ START UNSAFE STYLES ------
				// These styles are only needed for the UNSAFE legacy use case for Jira + Confluence.
				// When they aren't needed anymore we can delete them wholesale.
				<DangerouslyHoistCssVarToDocumentRoot
					variableName={UNSAFE_sideNavLayoutVar}
					value="0px"
					mediaQuery={media.above.md}
					responsiveValue={visibleOnDesktop ? clampedWidth : 0}
				/>
				// ------ END UNSAFE STYLES ------
			)}
			{/**
			 * InteractionSurface is the parent because the PanelSplitterProvider renders a scroll container.
			 *
			 * Using `height: 100%` inside the scroll container will make it the height of the scroll container,
			 * not fill the scroll container.
			 *
			 * We could use `height: max-content` instead but that is not currently an allowed value,
			 * and this is simpler.
			 */}
			<InteractionSurface xcss={styles.interactionSurface}>
				<PanelSplitterProvider
					panelRef={navRef}
					panelWidth={width}
					onCompleteResize={setWidth}
					resizeBounds={widthResizeBounds}
					resizingCssVar={panelSplitterResizingVar}
					isEnabled={sideNavState === 'idle' || sideNavState === 'hiddenMobileOnly'}
				>
					{children}
				</PanelSplitterProvider>
			</InteractionSurface>
		</nav>
	);
}

/**
 * We need an additional component layer so we can wrap the side nav in a `OpenLayerObserver` and have access to the
 * context value.
 */
function SideNavInternal({
	children,
	defaultCollapsed,
	defaultWidth = 320,
	testId,
	label = 'Side Navigation',
	onExpand,
	onCollapse,
	id,
}: SideNavProps) {
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	useSkipLink(CID, label);
	const { visibleOnDesktop, visibleOnMobile, setVisibleOnDesktop } = useSideNavVisibility({
		defaultCollapsed,
	});
	const [width, setWidth] = useState(defaultWidth);
	const clampedWidth = `clamp(${widthResizeBounds.min}, ${width}px, ${widthResizeBounds.max})`;
	const dangerouslyHoistSlotSizes = useContext(DangerouslyHoistSlotSizes);
	const navRef = useRef<HTMLDivElement | null>(null);
	const toggleButtonElement = useContext(SideNavToggleButtonElement);
	const homeActionsElement = useContext(HomeActionsElement);
	const devTimeOnlyAttributes: Record<string, string | boolean> = {};
	const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
	const openLayerObserver = useOpenLayerObserver();
	const isMousedOverFlyoutZones = useRef(false);

	const sideNavState = getSideNavState({
		visibleOnDesktop,
		visibleOnMobile,
		isFlyoutVisible,
	});

	// A timeout reference is used to close the flyout after a delay when the user mouses out of the flyout area, and to allow
	// us to cancel the close if the user mouses back in.
	const closeFlyoutTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const cancelPendingFlyoutClose = useCallback(() => {
		if (closeFlyoutTimerRef.current) {
			clearTimeout(closeFlyoutTimerRef.current);
			closeFlyoutTimerRef.current = null;
		}
	}, []);

	const openFlyout = useCallback(() => {
		cancelPendingFlyoutClose();
		setIsFlyoutVisible(true);
	}, [cancelPendingFlyoutClose]);

	// Starts a timer to close the flyout after a delay. It will only start a new timer if one isn't already pending.
	const tryStartCloseFlyoutTimer = useCallback(() => {
		if (closeFlyoutTimerRef.current) {
			// There is already a close timer pending, so we won't need to start another one.
			return;
		}

		if (openLayerObserver.getCount() > 0) {
			// There are open layers (e.g. popups) within the side nav - so we don't want to close the flyout.
			return;
		}

		closeFlyoutTimerRef.current = setTimeout(() => {
			closeFlyoutTimerRef.current = null;
			setIsFlyoutVisible(false);
		}, sideNavFlyoutCloseDelayMs);
	}, [openLayerObserver]);

	const toggleVisibility = useToggleSideNav();

	// Sync the visibility in context with the local state value after SSR hydration, as it contains the default value from props
	useEffect(() => {
		setVisibleOnDesktop(visibleOnDesktop);
	}, [setVisibleOnDesktop, visibleOnDesktop]);

	useSideNavVisibilityCallbacks({
		onExpand,
		onCollapse,
		visibleOnDesktop,
		visibleOnMobile,
	});

	useEffect(() => {
		const mediaQueryList = window.matchMedia('(min-width: 64rem)');
		return bind(mediaQueryList, {
			type: 'change',
			listener() {
				if (mediaQueryList.matches) {
					// We're transitioning from tablet to desktop viewport size.
					// We forcibly show the side nav if it was shown on mobile.
					if (visibleOnMobile && !visibleOnDesktop) {
						toggleVisibility();
					}
				}
			},
		});
	}, [toggleVisibility, visibleOnDesktop, visibleOnMobile]);

	useEffect(() => {
		if (!toggleButtonElement) {
			return;
		}

		return bind(toggleButtonElement, {
			type: 'mouseenter',
			listener() {
				// Only flyout the side nav if the user hovered while the side nav was collapsed
				if (visibleOnDesktop) {
					return;
				}

				// Only flyout the side nav on desktop viewports
				const { matches } = window.matchMedia('(min-width: 64rem)');
				if (matches) {
					openFlyout();
					isMousedOverFlyoutZones.current = true;
				}
			},
		});
	}, [openFlyout, toggleButtonElement, visibleOnDesktop]);

	useEffect(() => {
		if (!toggleButtonElement) {
			return;
		}

		return bind(toggleButtonElement, {
			type: 'mouseleave',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (!isFlyoutVisible) {
					return;
				}

				tryStartCloseFlyoutTimer();
				isMousedOverFlyoutZones.current = false;
			},
		});
	}, [tryStartCloseFlyoutTimer, isFlyoutVisible, toggleButtonElement]);

	useEffect(() => {
		if (!navRef.current) {
			return;
		}

		return bind(navRef.current, {
			type: 'mouseenter',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (visibleOnDesktop || !isFlyoutVisible) {
					return;
				}

				// If the user mouses back over the side nav we want to cancel any pending flyout close timers
				cancelPendingFlyoutClose();
				isMousedOverFlyoutZones.current = true;
			},
		});
	}, [cancelPendingFlyoutClose, isFlyoutVisible, visibleOnDesktop]);

	useEffect(() => {
		if (!navRef.current) {
			return;
		}

		return bind(navRef.current, {
			type: 'mouseleave',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (!isFlyoutVisible) {
					return;
				}

				isMousedOverFlyoutZones.current = false;
				tryStartCloseFlyoutTimer();
			},
		});
	}, [cancelPendingFlyoutClose, isFlyoutVisible, tryStartCloseFlyoutTimer, visibleOnDesktop]);

	useEffect(() => {
		if (!homeActionsElement || !toggleButtonElement) {
			return;
		}

		return bind(homeActionsElement, {
			type: 'mouseover',
			listener(event) {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (visibleOnDesktop || !isFlyoutVisible) {
					return;
				}

				if (event.target === homeActionsElement) {
					// The mouse is directly over the home actions container, so cancel any pending flyout closes.
					cancelPendingFlyoutClose();
					isMousedOverFlyoutZones.current = true;
					return;
				}

				if (event.target instanceof Element && toggleButtonElement.contains(event.target)) {
					// The mouse is over the toggle button or any of its children, so we don't want to close the flyout.
					// We also don't need to cancel any pending closes, as we have separate event listeners for the toggle button mouse events.
					isMousedOverFlyoutZones.current = true;
					return;
				}

				// The user has moused over a child element of the home actions container that isn't the toggle button, e.g. the app switcher or nav logo,
				// so we should close the flyout (with a delay).
				tryStartCloseFlyoutTimer();
				isMousedOverFlyoutZones.current = false;
			},
		});
	}, [
		cancelPendingFlyoutClose,
		tryStartCloseFlyoutTimer,
		homeActionsElement,
		isFlyoutVisible,
		toggleButtonElement,
		visibleOnDesktop,
	]);

	useEffect(() => {
		if (!homeActionsElement) {
			return;
		}

		return bind(homeActionsElement, {
			type: 'mouseleave',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (!isFlyoutVisible) {
					return;
				}

				// The mouse has left the home actions container, so we should close the flyout with a delay.
				tryStartCloseFlyoutTimer();
				isMousedOverFlyoutZones.current = false;
			},
		});
	}, [cancelPendingFlyoutClose, tryStartCloseFlyoutTimer, homeActionsElement, isFlyoutVisible]);

	useEffect(() => {
		// Close the flyout if there are no more layers open and the user is not mousing over the
		// flyout areas (side nav, top bar home actions element)
		return openLayerObserver.onChange(function onChange({ count }) {
			if (!isMousedOverFlyoutZones.current && count === 0) {
				setIsFlyoutVisible(false);
			}
		});
	}, [openLayerObserver]);

	useEffect(() => {
		// Clear flyout close timer if being unmounted
		return cancelPendingFlyoutClose;
	}, [cancelPendingFlyoutClose]);

	if (process.env.NODE_ENV !== 'production') {
		const visible: string[] = [];
		if (visibleOnMobile) {
			visible.push('small');
		}
		if (visibleOnDesktop) {
			visible.push('large');
		}
		if (isFlyoutVisible) {
			visible.push('flyout');
		}
		devTimeOnlyAttributes['data-visible'] = visible.length ? visible.join(',') : 'false';
	}

	return (
		<nav
			id={CID}
			{...devTimeOnlyAttributes}
			data-layout-slot
			aria-label={label}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={
				{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					[sideNavVar]: clampedWidth,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				} as CSSProperties
			}
			ref={navRef}
			css={[
				styles.root,
				sideNavState === 'hiddenMobileOnly' && styles.hiddenMobileOnly,
				sideNavState === 'hiddenDesktopOnly' && styles.hiddenDesktopOnly,
				sideNavState === 'hiddenMobileAndDesktop' && styles.hiddenMobileAndDesktop,
				sideNavState === 'flyout' && styles.flyout,
			]}
			data-testid={testId}
		>
			{dangerouslyHoistSlotSizes && (
				// ------ START UNSAFE STYLES ------
				// These styles are only needed for the UNSAFE legacy use case for Jira + Confluence.
				// When they aren't needed anymore we can delete them wholesale.
				<DangerouslyHoistCssVarToDocumentRoot
					variableName={UNSAFE_sideNavLayoutVar}
					value="0px"
					mediaQuery={media.above.md}
					responsiveValue={visibleOnDesktop ? clampedWidth : 0}
				/>
				// ------ END UNSAFE STYLES ------
			)}
			{/**
			 * InteractionSurface is the parent because the PanelSplitterProvider renders a scroll container.
			 *
			 * Using `height: 100%` inside the scroll container will make it the height of the scroll container,
			 * not fill the scroll container.
			 *
			 * We could use `height: max-content` instead but that is not currently an allowed value,
			 * and this is simpler.
			 */}
			<InteractionSurface xcss={styles.interactionSurface}>
				<PanelSplitterProvider
					panelRef={navRef}
					panelWidth={width}
					onCompleteResize={setWidth}
					resizeBounds={widthResizeBounds}
					resizingCssVar={panelSplitterResizingVar}
					isEnabled={sideNavState === 'idle' || sideNavState === 'hiddenMobileOnly'}
				>
					{children}
				</PanelSplitterProvider>
			</InteractionSurface>
		</nav>
	);
}

export function SideNav({
	children,
	defaultCollapsed,
	defaultWidth = 320,
	testId,
	label = 'Side Navigation',
	onExpand,
	onCollapse,
	id,
}: SideNavProps) {
	if (fg('platform_design_system_team_layering_observer')) {
		return (
			<OpenLayerObserver>
				<SideNavInternal
					defaultCollapsed={defaultCollapsed}
					defaultWidth={defaultWidth}
					testId={testId}
					label={label}
					onExpand={onExpand}
					onCollapse={onCollapse}
					id={id}
				>
					{children}
				</SideNavInternal>
			</OpenLayerObserver>
		);
	}

	return (
		<SideNavInternalLegacy
			defaultCollapsed={defaultCollapsed}
			defaultWidth={defaultWidth}
			testId={testId}
			label={label}
			onExpand={onExpand}
			onCollapse={onCollapse}
			id={id}
		>
			{children}
		</SideNavInternalLegacy>
	);
}
