import React, { type ReactNode } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { PACKAGE_NAME, TEAM_NAME } from './src/constants';

export type EPCheckProps = {
	onComplete: () => void;
	children: ReactNode;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncDeferEditionEPCheck = lazyAfterPaint(
	() =>
		import(/* webpackChunkName: "async-jira-defer-edition-ep-check" */ './src/async').then(
			({ EPCheck }) => EPCheck,
		),
	{ ssr: false },
);

// Internal components are wrapped by further JSErrorBoundaries where-ever UI is loaded,
// this is here to report errors thrown due to lazy loading this top level component in global shared components.
export const AsyncDeferEditionEPCheckWrapper = (props: EPCheckProps) => (
	<ErrorBoundary id="defer-edition-ep-check-async" packageName={PACKAGE_NAME}>
		<ReportErrors teamName={TEAM_NAME} id="defer-edition-ep-check-async" packageName={PACKAGE_NAME}>
			<AsyncDeferEditionEPCheck {...props} />
		</ReportErrors>
	</ErrorBoundary>
);
