import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	NAMESPACE,
	ONE_WEEK,
	FOUR_WEEKS,
	LOCAL_STORAGE_FULL_ERROR_CODE,
	LOCAL_STORAGE_FULL_FF_ERROR_CODE,
} from '../../constants';
/**
 * Enhances storage operations with automated cleanup capabilities. This includes tracking
 * item timestamps for age-based cleanup, handling storage limits by removing older items,
 * and providing utility functions for managing storage contents. Designed to ensure
 * efficient use of storage space without manual intervention.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function cleanUpPlugin(this: any) {
	// eslint-disable-next-line @typescript-eslint/no-this-alias
	const self = this;
	const timestamps = self.createStore(self.storage, null, self._namespacePrefix + NAMESPACE);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function set(superFn: any, key: any, val: any) {
		try {
			if (!self.hasNamespace(NAMESPACE)) {
				timestamps.set(key, Date.now());
			}
			superFn();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// storage is full. Relevant only for localStorage
			if (e.code === LOCAL_STORAGE_FULL_ERROR_CODE || e.code === LOCAL_STORAGE_FULL_FF_ERROR_CODE) {
				// 22 - the correct code most browsers use
				// 1014 - Firefox's code
				fireErrorAnalytics({
					meta: {
						id: 'browserLocalStorageFullError',
						packageName: 'jiraBrowserStorageProviders',
						teamName: 'magma',
					},
					attributes: {
						namespace: self._namespacePrefix,
					},
					error: e,
					skipSentry: true,
				});
				if (self.cleanWhenFull()) {
					self.set(superFn, key, val);
				}
			} else {
				fireErrorAnalytics({
					meta: {
						id: 'storageCleanupPluginError',
						packageName: 'jiraBrowserStorageProviders',
						teamName: 'magma',
					},
					attributes: {
						isLegacyFunction: false,
					},
					error: e,
				});
				throw e;
			}
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function remove(superFn: any, key: any) {
		if (!self.hasNamespace(NAMESPACE)) {
			timestamps.remove(key);
		}
		return superFn();
	}

	// quite stupid way of getting totals, but current library supports only this.each()
	function getTotalCount() {
		let count = 0;
		self.each(() => {
			count += 1;
		});
		return count;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function cleanWhenFull(superFn: any, minAge = FOUR_WEEKS) {
		const currTime = Date.now();
		const numKeysBefore = self.getTotalCount();

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		self.each((_value: any, key: any) => {
			// return correct value for current collection
			// and return null for timestamps collection
			const created = !self.hasNamespace(NAMESPACE) ? timestamps.get(key) : null;
			if (typeof created === 'number' && currTime - created > minAge) {
				self.remove(key);
			}
		});
		const itemsRemovedCount = numKeysBefore - self.getTotalCount();
		if (!itemsRemovedCount && minAge - ONE_WEEK >= ONE_WEEK) {
			return self.cleanWhenFull(minAge - ONE_WEEK);
		}
		return itemsRemovedCount === 0;
	}
	return {
		set,
		remove,
		cleanWhenFull,
		getTotalCount,
	};
}
export default cleanUpPlugin;
