/**
 * @generated SignedSource<<5324123194c5d99dcdf98bdf0fc7c0d5>>
 * @relayHash 3192a22253811f1a2a2e30511206336b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ced02a49e8c3383ab97232deb3bff78f7a428d9c0dc55cc8e829f0172d2726ee

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraJqlViewContext = "JSW_PLANS" | "JWM" | "SHADOW_REQUEST" | "%future added value";
export type hydrateJqlBuilderQuery$variables = {
  accountId: string;
  cloudId: string;
  includeJira: boolean;
  includeUser: boolean;
  isNinShowOutOfContextFieldsAsOnlyUnselectable: boolean;
  jql: string;
  viewContext?: JiraJqlViewContext | null | undefined;
};
export type hydrateJqlBuilderQuery$data = {
  readonly jira?: {
    readonly jqlBuilder: {
      readonly hydrateJqlQuery: {
        readonly __typename: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"jqlEditor_jqlBuilderBasic_JQLEditorUI" | "useHydratedValues" | "useHydratedValues_cascadeSelectPicker" | "useQuery">;
};
export type hydrateJqlBuilderQuery = {
  response: hydrateJqlBuilderQuery$data;
  variables: hydrateJqlBuilderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeJira"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeUser"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isNinShowOutOfContextFieldsAsOnlyUnselectable"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jql"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewContext"
},
v7 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "jql"
  },
  {
    "kind": "Variable",
    "name": "viewContext",
    "variableName": "viewContext"
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v10 = [
  (v9/*: any*/)
],
v11 = {
  "kind": "ScalarField",
  "name": "id"
},
v12 = [
  (v9/*: any*/),
  {
    "kind": "ScalarField",
    "name": "picture"
  },
  (v11/*: any*/)
],
v13 = {
  "kind": "ScalarField",
  "name": "jqlTerm"
},
v14 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v15 = {
  "kind": "ScalarField",
  "name": "key"
},
v16 = [
  {
    "concreteType": "JiraStatusCategory",
    "kind": "LinkedField",
    "name": "statusCategory",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "colorName"
      },
      (v11/*: any*/)
    ]
  }
],
v17 = {
  "kind": "ScalarField",
  "name": "optionId"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "name": "hydrateJqlBuilderQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "useHydratedValues"
      },
      {
        "kind": "FragmentSpread",
        "name": "jqlEditor_jqlBuilderBasic_JQLEditorUI"
      },
      {
        "kind": "FragmentSpread",
        "name": "useQuery"
      },
      {
        "kind": "FragmentSpread",
        "name": "useHydratedValues_cascadeSelectPicker"
      },
      {
        "condition": "includeJira",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v7/*: any*/),
                "concreteType": "JiraJqlBuilder",
                "kind": "LinkedField",
                "name": "jqlBuilder",
                "plural": false,
                "selections": [
                  {
                    "args": (v8/*: any*/),
                    "kind": "LinkedField",
                    "name": "hydrateJqlQuery",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "hydrateJqlBuilderQuery",
    "selections": [
      {
        "condition": "includeUser",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              }
            ],
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v12/*: any*/)
          }
        ]
      },
      {
        "condition": "includeJira",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v7/*: any*/),
                "concreteType": "JiraJqlBuilder",
                "kind": "LinkedField",
                "name": "jqlBuilder",
                "plural": false,
                "selections": [
                  {
                    "args": (v8/*: any*/),
                    "kind": "LinkedField",
                    "name": "hydrateJqlQuery",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "jql"
                          },
                          {
                            "kind": "LinkedField",
                            "name": "fields",
                            "plural": true,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v13/*: any*/),
                                  {
                                    "concreteType": "JiraJqlField",
                                    "kind": "LinkedField",
                                    "name": "field",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      {
                                        "concreteType": "JiraJqlSearchTemplate",
                                        "kind": "LinkedField",
                                        "name": "searchTemplate",
                                        "plural": false,
                                        "selections": [
                                          (v15/*: any*/)
                                        ]
                                      },
                                      {
                                        "condition": "isNinShowOutOfContextFieldsAsOnlyUnselectable",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "shouldShowInContext"
                                          }
                                        ]
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "type"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "description"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "operators"
                                      },
                                      {
                                        "concreteType": "JiraFieldType",
                                        "kind": "LinkedField",
                                        "name": "fieldType",
                                        "plural": false,
                                        "selections": [
                                          (v14/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "name": "values",
                                    "plural": true,
                                    "selections": [
                                      (v9/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v13/*: any*/),
                                          {
                                            "kind": "LinkedField",
                                            "name": "values",
                                            "plural": true,
                                            "selections": [
                                              (v9/*: any*/),
                                              (v13/*: any*/),
                                              (v14/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraProject",
                                                    "kind": "LinkedField",
                                                    "name": "project",
                                                    "plural": false,
                                                    "selections": [
                                                      (v15/*: any*/),
                                                      {
                                                        "concreteType": "JiraAvatar",
                                                        "kind": "LinkedField",
                                                        "name": "avatar",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "small"
                                                          }
                                                        ]
                                                      },
                                                      (v11/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraJqlProjectFieldValue"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": (v16/*: any*/),
                                                "type": "JiraJqlStatusFieldValue"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": (v16/*: any*/),
                                                "type": "JiraJqlStatusCategoryFieldValue"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssueType",
                                                    "kind": "LinkedField",
                                                    "name": "issueTypes",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraAvatar",
                                                        "kind": "LinkedField",
                                                        "name": "avatar",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "xsmall"
                                                          }
                                                        ]
                                                      },
                                                      (v11/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraJqlIssueTypeFieldValue"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": (v12/*: any*/)
                                                  }
                                                ],
                                                "type": "JiraJqlUserFieldValue"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraPriority",
                                                    "kind": "LinkedField",
                                                    "name": "priority",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "iconUrl"
                                                      },
                                                      (v11/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraJqlPriorityFieldValue"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssue",
                                                    "kind": "LinkedField",
                                                    "name": "issue",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraIssueFieldConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fields",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraIssueFieldEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                              {
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                  (v9/*: any*/),
                                                                  {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                      {
                                                                        "kind": "ScalarField",
                                                                        "name": "text"
                                                                      }
                                                                    ],
                                                                    "type": "JiraSingleLineTextField"
                                                                  },
                                                                  (v11/*: any*/)
                                                                ]
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      (v11/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraJqlIssueFieldValue"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraSprint",
                                                    "kind": "LinkedField",
                                                    "name": "sprint",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "state"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "boardName"
                                                      },
                                                      (v11/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraJqlSprintFieldValue"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v17/*: any*/),
                                                  {
                                                    "concreteType": "JiraJqlCascadingOptionFieldValue",
                                                    "kind": "LinkedField",
                                                    "name": "parentOption",
                                                    "plural": false,
                                                    "selections": [
                                                      (v14/*: any*/),
                                                      (v13/*: any*/),
                                                      (v17/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraJqlCascadingOptionFieldValue"
                                              }
                                            ]
                                          }
                                        ],
                                        "type": "JiraJqlQueryHydratedValue"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v13/*: any*/),
                                          {
                                            "concreteType": "QueryError",
                                            "kind": "LinkedField",
                                            "name": "error",
                                            "plural": false,
                                            "selections": (v10/*: any*/)
                                          }
                                        ],
                                        "type": "JiraJqlQueryHydratedError"
                                      }
                                    ]
                                  }
                                ],
                                "type": "JiraJqlQueryHydratedField"
                              }
                            ]
                          }
                        ],
                        "type": "JiraJqlHydratedQuery"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "message"
                          }
                        ],
                        "type": "QueryError"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ced02a49e8c3383ab97232deb3bff78f7a428d9c0dc55cc8e829f0172d2726ee",
    "metadata": {},
    "name": "hydrateJqlBuilderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "44d13fe5e219301bf9987a6926b9f257";

export default node;
